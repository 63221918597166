import React from 'react';
import Hero from "../components/Hero";
import AboutImg from "../assets/shirtings.jpg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SliderProduct from "../components/SliderProduct";

import img1 from '../assets/productSlider/shirting1.jpg';
import img2 from '../assets/productSlider/shirting2.jpg';
import img3 from '../assets/productSlider/shirting3.jpg';
import img4 from '../assets/productSlider/shirting4.jpg';
import img5 from '../assets/productSlider/shirting5.jpg';
import img6 from '../assets/productSlider/shirting6.jpg';

const Shirtings = () => {
    return (
        <>
            <Navbar />

            <Hero
                hCName="aboutHero"
                hImg={AboutImg}
                hTitle="Shirtings"
                hPara="Home / Products / Shirtings"
            />

            <SliderProduct
                Title="Shirtings"
                Text1="We at shaaria weave the finest shirting fabrics as per our client’s requirements. We offer 100% cotton, Poly cotton 90:10, 80:20, 75:25, 70:30, 60:40, 55:45 & 50:50. We also do lycra shirting in 2 way stretch."
                Text2="Our shirting range does not stops here but we also manufacture Oxfords, Satin, Plains, Twill, Dobby which can suit any age group."
                imgsrc1={img1}
                imgsrc2={img2}
                imgsrc3={img3}
                imgsrc4={img4}
                imgsrc5={img5}
                imgsrc6={img6}
            />
            <Footer />
        </>
    )
}

export default Shirtings;