import React from 'react';


const AboutCard = (props) => {
    return (
        <>
            <div className="container padT150 padB80">
                <div className="row">
                    <div className="col-lg-5 col-md-6">
                        <div className="about-us-img">
                            <img src={props.Img} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6 padL50">
                        <div className="about-info-wrapper">
                            <h2 className='Title' data-aos="fade-up" data-aos-duration="500">{props.Title}</h2>
                            <p className='pt-3' data-aos="fade-up" data-aos-duration="1000">{props.Text1}</p>
                            <p data-aos="fade-up" data-aos-duration="1000">{props.Text2}</p>
                            <p data-aos="fade-up" data-aos-duration="1000">{props.Text3}</p>
                            <div className="pt-4" data-aos="fade-up" data-aos-duration="1000">
                                <a href={props.url} className={props.cls}>Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutCard;