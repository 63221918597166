import React, { useState, useEffect, useRef } from 'react'
import emailjs from '@emailjs/browser';

const ContactForm = () => {

    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [msg, setMsg] = useState("");
    const [message, setMessage] = useState('');

    const form = useRef();

    useEffect(() => {
        setTimeout(function () {
            setMessage(true)
        }, 9000);
    },
        [message])


    const sendEmail = async (e) => {
        e.preventDefault();


        setFname("");
        setLname("");
        setEmail("");
        setSubject("");
        setMsg("");

        setMessage(`Thank you ${fname} ${lname} for enquiry. We will back to you asap.`);

        emailjs.sendForm('service_ssj57og', 'template_aoz4k9f', form.current, 'a2QkHSfptD5sqx8lc')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

    }


    return (
        <>
            <div className="contact-form">
                <div className="contact-form-info">
                    <div className="contact-title">
                        <h3>TELL US YOUR PROJECT</h3>
                    </div>
                    <form id="contact-form" ref={form} onSubmit={sendEmail}>
                        <div className="contact-page-form">
                            <div className="contact-input">
                                <div className="contact-inner">
                                    <input type="text" placeholder="First Name *" name="first-name" value={fname} onChange={(e) => setFname(e.target.value)} />
                                </div>
                                <div className="contact-inner">
                                    <input type="text" placeholder="Last Name *" name="last-name" value={lname} onChange={(e) => setLname(e.target.value)} />
                                </div>
                                <div className="contact-inner">
                                    <input type="text" placeholder="Email *" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="contact-inner">
                                    <input type="text" placeholder="Subject *" name="subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                                </div>
                                <div className="contact-inner contact-message">
                                    <textarea placeholder="Message *" name="message" value={msg} onChange={(e) => setMsg(e.target.value)}></textarea>
                                </div>
                            </div>
                            <div className="contact-submit-btn">
                                <button className="submit-btn" type="submit" >Send Email</button>
                                <p className="form-messege pt-3">{message}</p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ContactForm;