import Hero from "../components/Hero";
import ContactImg from "../assets/contact.jpg";
import Navbar from "../components/Navbar";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
// import Breadcrumbs from "../components/Breadcrumbs";

function Contact () {
    return(
        <>
            <Navbar/>
            <Hero 
                hCName="aboutHero" 
                hImg={ContactImg} 
                hTitle="Contact Us"
                hPara="Home / Contact"
            />
            {/* <Breadcrumbs 
            pName = "Contact Us"
            /> */}
            <ContactUs />
            <Footer />
            
        </>
    )
}

export default Contact;