
import { NavLink } from 'react-router-dom';
import Dropdown from './Dropdown';
import { useState, useEffect, useRef } from "react";


export const menuItem = [
    {
        title: "Home",
        url: "/",
        cName: "nav-links",

    },
    {
        title: "Overview",
        url: "/About",
        cName: "nav-links",

    },
    {
        title: "Products",
        cName: "nav-links",
        submenu: [
            {
                title: 'Suiting',
                submenu: [
                    {
                        title: 'Cotton',
                        url: '/Cotton',
                    },
                    {
                        title: 'Poly base',
                        url: '/Polybase',
                    },
                    {
                        title: 'Poly viscose',
                        url: '/Polyviscose',
                    },
                    {
                        title: 'Poly wool',
                        url: '/Polywool',
                    },

                ],
            },
            {
                title: 'Shirting',
                url: '/shirtings',
            },
            {
                title: 'Garments',
                submenu: [
                    {
                        title: 'Overview',
                        url: '/garments',
                    },
                    {
                        title: 'Men',
                        url: '/men',
                    },
                    {
                        title: 'Women',
                        url: '/women',
                    },
                ],
            },
            {
                title: 'Uniforms',
                url: '/uniforms',
            },
            {
                title: 'Sustainable Range',
                url: '/Sustainable-range',
            },
        ],
    },
    {
        title: "Career",
        url: "/career",
        cName: "nav-links",
    },
    {
        title: "Contact Us",
        url: "/Contact",
        cName: "nav-links",
    }
];


const MenuItems = ({ items, depthLevel }) => {
    const [dropdown, setDropdown] = useState(false);

    let ref = useRef();
    useEffect(() => {
        const handler = (event) => {
            if (dropdown && ref.current && !ref.current.contains(event.target)) {
                setDropdown(false);
            }
        };
        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("touchstart", handler);
        };
    }, [dropdown]);

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    return (
        <>
            <li className="menuLink" ref={ref}>
                {items.submenu ? (
                    <>
                        <button type="button" aria-haspopup="menu" aria-expanded={dropdown ? "true" : "false"}
                            onClick={() => setDropdown((prev) => !prev)} >
                            {items.title}{' '}
                            {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />}
                        </button>
                        <Dropdown submenus={items.submenu} dropdown={dropdown} depthLevel={depthLevel} />

                    </>
                ) : (
                    <NavLink onClick={scrollToTop} to={items.url} className={items.cName}>{items.title}</NavLink>
                )}
            </li>

        </>
    );
};

export default MenuItems;