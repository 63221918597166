import React from 'react';
import './about.css';
import AboutCard from './AboutCard';
import AboutImg from '../assets/aboutus.jpg';
import Vision from './Vision';
import FactoryImg from './FactoryImg';

const AboutPage = () => {
    return (
        <>
            <div className="content-wraper">

                <AboutCard
                    Img={AboutImg}
                    Title="Overview"
                    Text1="Shaaria Corporation is a conclusion of 25 years expertise and experience of the management team. We are committed for perfection in our pre and post sales. We at Shaaria always strive to come up with innovative ideas, new product research, new development and trying to deliver the best product to our buyers is our ultimate goal. We have a variety range of Suiting’s, Shirting’s, Uniforms and Garments. We also deal in African wax prints."
                    Text2="Shaaria Corporation is equipped with weaving, finishing and garmenting manufacturing houses with great team focusing on best quality production, innovative ideas, deadline oriented and lot more. We have monthly production capacity of 300,000 mtrs of suiting, 200,000 mtrs of shirting and 100,000 Garments."
                    Text3="Quality assurance and innovation is our USP. We provide all the services or requirements mentioned from buyer’s end for testing."
                    cls ="read-more-btn displayNone"
                />
                <FactoryImg />
                <Vision />
            </div>
        </>
    )
}

export default AboutPage;