import React from 'react';
import img1 from '../assets/factory1.jpg';
import img2 from '../assets/factory2.jpg';
import img3 from '../assets/factory3.jpg';

const FactoryData = [
    {
        imgsrc: img1,
    },
    {
        imgsrc: img2,
    },
    {
        imgsrc: img3,
    }
];

const FactoryImg = () => {
    return (
        <>
            <div className="container">
                <div className="row">

                    {
                        FactoryData.map((val, index) => {
                            return (
                                <div className="col-lg-4 col-md-6 pb-5" data-aos="zoom-out" data-aos-duration="2000" key={index}>
                                    <div className="card">
                                        <img src={val.imgsrc} alt="" />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default FactoryImg;