import React from 'react';
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AboutCard from '../components/AboutCard';
import Img from '../assets/values-inner.jpg';
import Img2 from '../assets/values.jpg';

const Values = () => {
    return (
        <>
            <Navbar />

            <Hero
                hCName="aboutHero"
                hImg={Img}
                hTitle="Our Values"
                hPara="Home / Overview / Values"
            />
            
            <AboutCard
                    Img={Img2}
                    Title="Our Values"
                    Text1="Altruism, Integrity, Customer Satisfaction, Product Excellence, Ownership"
                    cls ="read-more-btn displayNone"
                />

            
            <Footer />
        </>
    )
}

export default Values;