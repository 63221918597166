import React from 'react';
import Hero from "../components/Hero";
import AboutImg from "../assets/garments.jpg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SliderProduct from "../components/SliderProduct";
import Banners from "../components/Banners";

import img1 from '../assets/productSlider/mens1.jpg';
import img2 from '../assets/productSlider/womens2.jpg';
import img3 from '../assets/productSlider/mens3.jpg';
import img4 from '../assets/productSlider/womens6.jpg';
import img5 from '../assets/productSlider/mens5.jpg';
import img6 from '../assets/productSlider/mens6.jpg';


const Garments = () => {
    return (
        <>
            <Navbar />

            <Hero
                hCName="aboutHero"
                hImg={AboutImg}
                hTitle="Garments"
                hPara="Home / Products / Garments"
            />

            <SliderProduct
                Title="Garments"
                Text1="Being into fabric sales, we expanded ourselves into garments as well. We have a capacity of producing 100000 garments monthly. We produce all kind of formal, uniforms, institutional garments etc. our forte is formal attire."
                imgsrc1={img1}
                imgsrc2={img2}
                imgsrc3={img3}
                imgsrc4={img4}
                imgsrc5={img5}
                imgsrc6={img6}
            />
            <Banners />
            <Footer />
        </>
    )
}

export default Garments;