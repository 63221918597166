import Img1 from '../assets/vision.jpg';
import Img2 from '../assets/mission.jpg';
import Img3 from '../assets/values.jpg';

const VisionData = [
    {
        imgsrc: Img1,
        iClass: "fa-regular fa-eye",
        title : "Our Vision",
        para : "Our goal is to satisfy every customer we have in our palate with the best we have and to improve more with the mistakes we can learn from. We not only want to widden our scope of fabrics but we are marching forward with a dream of becoming the defination of precision on quality and in time parameters.",
        url : "Vision",
    },
    {
        imgsrc: Img2,
        iClass: "fa fa-bullseye",
        title : "Our Mission",
        para : "We are into business of providing the best fabric and garments we can with the time limitations given to us. we make sure that the required quality is finished under the required standards and also is not hazardious to the human race and environment.",
        url : "Mission",
    },
    {
        imgsrc: Img3,
        iClass: "fa-solid fa-crosshairs",
        title : "Our Values",
        para : "Altrusium, Integrity, Customer Satisfaction, Product Excellence, Ownership",
        url : "Values",
    }
];

export default VisionData;