import { Component} from "react";
import "./Navbar.css";
import {menuItem} from './MenuItems';
import MenuItems from './MenuItems';
import { Link } from "react-router-dom";
import Logo from "../assets/logo.png";


class Navbar extends Component {


    state = { clicked: false };
    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }
    render() {
        return (


            <nav className="NavbarItems">
                <h1 className="navbar-logo">
                    <Link to="/"><img src={Logo} alt="" /></Link>
                </h1>

                <div className="hamberger-icon" onClick={this.handleClick}>
                    <i className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>
                </div>

                <ul className={this.state.clicked ? "nav-menu is-active" : "nav-menu"}>
                    {menuItem.map((menu, index) => {
                        const depthLevel = 0;
                        return <MenuItems items={menu} key={index} depthLevel={depthLevel}/>;
                    })}
                </ul>
            </nav>


        )
    }
}

export default Navbar;