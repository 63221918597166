import React from 'react';
import "./brand.css";
import ProductData from './ProductData';
import SustainableData from './SustainableData';


const Product = () => {
    return (
        <>
            <div className="container padB50 padT50">
                <div className="row ">
                    <div className="col-lg-12 col-md-12 text-center productContent">
                        <h2 className="Title" data-aos="fade-up" data-aos-duration="1000">Our Products</h2>
                        <p className="productPara pt-2 pb-4">Quality assurance and innovation is our USP</p>
                        <hr />
                    </div>
                </div>
                <div className="row">

                    {
                        ProductData.map((val, index) => {
                            return (
                                <div className="col-lg-6 col-md-6 pb-4" data-aos="zoom-out" data-aos-duration="2000" key={index}>
                                    <div className="card">
                                        <div className='overHid'>
                                            <a href={val.url}><img src={val.imgsrc} className="card-img-top" alt="..." /></a>
                                        </div>
                                        <div className="card-body text-center">
                                            <a href={val.url}><h5 className='pTitle'>{val.title}</h5></a>
                                            <a href={val.url}>Read More</a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="container padT50">
                <div className="row">
                    <div className="col-lg-12 col-md-12 text-center productContent">
                        <h2 className="Title pb-5 sliderText">Sustainable Range / Eco-friendly</h2>
                    </div>
                    {
                        SustainableData.map((val, index) => {
                            return (
                                <div className="col-lg-4 col-md-6 pb-4" data-aos="zoom-out" data-aos-duration="2000" key={index}>
                                    <div className="card">
                                        <div className='overHid'>
                                            <a href={val.url}><img src={val.imgsrc} className="card-img-top" alt="..." /></a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        </>
    )
}

export default Product;