import React from "react";
import './testimonial.css'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import '../App.css';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";


// import required modules
import { Pagination } from "swiper";

import Testi1 from '../assets/testi1.webp'

const Testimonial = () => {
    return (
        <>
            <div className="container padT80 padB80">
                <div className="row ">
                    <div className="col-lg-12 col-md-12 text-center productContent">
                        <h2 className="Title" data-aos="fade-up" data-aos-duration="1000">Client Testimonials</h2>
                        <p className="productPara pt-2 pb-4">What they say</p>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                        
                    >
                        <SwiperSlide>

                            <div className="testimonial-content">
                                <p className="des_testimonial">Quality fabrics and best quality of shirt loved it. Excellent customer support. It will be my permanant choice.</p>
                                <div className="content_author">
                                    <div className="author-image">
                                        <img src={Testi1} alt="" />
                                    </div>
                                </div>
                                <p class="des_namepost">Manish Shinde</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial-content">
                                <p className="des_testimonial">Quality fabrics and best quality of shirt loved it. Excellent customer support. It will be my permanant choice.</p>
                                <div className="content_author">
                                    <div className="author-image">
                                        <img src={Testi1} alt="" />
                                    </div>
                                </div>
                                <p class="des_namepost">Manish Shinde</p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </>
    );
}
export default Testimonial;
