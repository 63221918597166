import React from 'react';
import Hero from "../components/Hero";
import AboutImg from "../assets/mens_bg2.jpg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SliderProduct from "../components/SliderProduct";

import img1 from '../assets/productSlider/mens1.jpg';
import img2 from '../assets/productSlider/mens2.jpg';
import img3 from '../assets/productSlider/mens3.jpg';
import img4 from '../assets/productSlider/mens4.jpg';
import img5 from '../assets/productSlider/mens5.jpg';
import img6 from '../assets/productSlider/mens6.jpg';

const Men = () => {

    const list = <ul className='GarmentList'>
        <li><b>Trousers:</b> Regular fit, Slim fit,  Narrow bottom, Flexi waist etc.</li>
        <li><b>Pants:</b> Formal pants, Casual Pants, Semi Formal pants etc.</li>
        <li><b>Short:</b> Cotton Shorts</li>
        <li><b>Suits:</b> Coat and pant</li>
        <li><b>Coats:</b> Full sleeves & Vest coat</li>
    </ul>

    return (
        <>
            <Navbar />

            <Hero
                hCName="aboutHero"
                hImg={AboutImg}
                hTitle="Garments"
                hPara="Home / Products / Garments"
            />

            <SliderProduct
                Title="Formal Men's Wear"
                Text1={list}
                imgsrc1={img1}
                imgsrc2={img2}
                imgsrc3={img3}
                imgsrc4={img4}
                imgsrc5={img5}
                imgsrc6={img6}
            />
            <Footer />
        </>
    )
}

export default Men;