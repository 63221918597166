import React from 'react';
import Hero from "../components/Hero";
import AboutImg from "../assets/Sustainable-rangeBg.jpg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SliderProduct from "../components/SliderProduct";

import img1 from '../assets/productSlider/Sustainable-range1.jpg';
import img2 from '../assets/productSlider/Sustainable-range2.jpg';
import img3 from '../assets/productSlider/Sustainable-range3.jpg';
import img4 from '../assets/productSlider/Sustainable-range4.jpg';
import img5 from '../assets/productSlider/Sustainable-range5.jpg';
import img6 from '../assets/productSlider/Sustainable-range6.jpg';

const SustainableRange = () => {
    return (
        <>
            <Navbar />

            <Hero
                hCName="aboutHero"
                hImg={AboutImg}
                hTitle="Sustainable Range"
                hPara="Home / Products / Sustainable Range"
            />

            <SliderProduct
                Title="Sustainable Range / Eco-friendly"
                Text1="To save mother nature, we are initiating a range of sustainable products. a product is sustainable if it’s produced and consumed in a manner that doesn’t hurt the environment. when it comes to sustainable, recycled products/eco-friendly products we offer a wide range to our clients with some value additions. our uniform fabric and garment are made from recycled polyester fibre also, into our shirting range we are using recycled polyester and also organic cotton."
                Text2="Our goal behind sustainable range is to influence the upcoming generations to thrive on this planet with the same thought process."
                imgsrc1={img1}
                imgsrc2={img2}
                imgsrc3={img3}
                imgsrc4={img4}
                imgsrc5={img5}
                imgsrc6={img6}
            />
            <Footer />
        </>
    )
}

export default SustainableRange;