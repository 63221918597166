import React from "react";
import Slider from "react-slick";
import {GrFormNextLink} from 'react-icons/gr'
import {GrFormPreviousLink} from 'react-icons/gr'


export default function SliderProduct(props) {


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        spaceBetween: 40,
        nextArrow: <GrFormNextLink />,
        prevArrow: <GrFormPreviousLink />,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    return (
        <>
            <div className="container padT80 padB80">
                <div className="row">
                    <div className="col-lg-12 col-md-6">
                        <div className="about-info-wrapper">
                            <h2 className='Title' data-aos="fade-up" data-aos-duration="700">{props.Title}</h2>
                            <p className='pt-5' data-aos="fade-up" data-aos-duration="1000">{props.Text1}</p>
                            <p className='' data-aos="fade-up" data-aos-duration="1000">{props.Text2}</p>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-6 pt-5">
                        <div className="">
                            <Slider {...settings}>
                                <div>
                                    <div className="single-team mb-4">
                                        <div className="team-imgae">
                                            <img src={props.imgsrc1} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="single-team mb-4">
                                        <div className="team-imgae">
                                            <img src={props.imgsrc2} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="single-team mb-4">
                                        <div className="team-imgae">
                                            <img src={props.imgsrc3} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="single-team mb-4">
                                        <div className="team-imgae">
                                            <img src={props.imgsrc4} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="single-team mb-4">
                                        <div className="team-imgae">
                                            <img src={props.imgsrc5} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="single-team mb-4">
                                        <div className="team-imgae">
                                            <img src={props.imgsrc6} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
