import React from 'react'
import Hero from "../components/Hero";
import AboutImg from "../assets/suitingBg.jpg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SliderProduct from "../components/SliderProduct";


import img1 from '../assets/productSlider/poly-wool1.jpg';
import img2 from '../assets/productSlider/poly-wool2.jpg';
import img3 from '../assets/productSlider/poly-wool3.jpg';
import img4 from '../assets/productSlider/poly-wool4.jpg';
import img5 from '../assets/productSlider/poly-wool5.jpg';
import img6 from '../assets/productSlider/poly-wool6.jpg';

const Polywool = () => {
    return (
        <>
            <Navbar />

            <Hero
                hCName="aboutHero"
                hImg={AboutImg}
                hTitle="Suitings"
                hPara="Home / Products / Suitings"
            />

            <SliderProduct
                Title="Poly Wool"
                Text1="When it comes to Attire and Dressing, you can definitely rely on us. We offer a exquisite range of wool blended suitings and all wool too. Our exciting range of Wool and blended suitings is comprised of PW 55:45, 50:50, 60:40, 70:30, 80:20, 90:10 and when it comes to reverse blend we have WP 100%, 80:20, 70:30, 60:40 etc. In wool fabrics also we have lycra range in both 2 way and 4 way stretch."
                imgsrc1={img1}
                imgsrc2={img2}
                imgsrc3={img3}
                imgsrc4={img4}
                imgsrc5={img5}
                imgsrc6={img6}
            />
            <Footer />
        </>
    )
}

export default Polywool;