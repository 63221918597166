import React from 'react';
import Hero from "../components/Hero";
import AboutImg from "../assets/uniforms_bg2.jpg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SliderProduct from "../components/SliderProduct";

import img1 from '../assets/productSlider/uniforms1.jpg';
import img2 from '../assets/productSlider/uniforms2.jpg';
import img3 from '../assets/productSlider/uniforms3.jpg';
import img4 from '../assets/productSlider/uniforms4.jpg';
import img5 from '../assets/productSlider/uniforms5.jpg';
import img6 from '../assets/productSlider/uniforms6.jpg';

const Uniforms = () => {


    return (
        <>
            <Navbar />

            <Hero
                hCName="aboutHero"
                hImg={AboutImg}
                hTitle="Uniforms"
                hPara="Home / Products / Uniforms"
            />

            <SliderProduct
                Title="Uniforms"
                Text1="We supply uniform fabrics to schools, hospitals, police, aviation, military etc."
                imgsrc1={img1}
                imgsrc2={img2}
                imgsrc3={img3}
                imgsrc4={img4}
                imgsrc5={img5}
                imgsrc6={img6}
            />
            <Footer />
        </>
    )
}

export default Uniforms;