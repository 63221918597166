import React from 'react';
import {motion} from 'framer-motion';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
// import Swiper core and required modules
import { Navigation, EffectFade} from 'swiper';
import 'swiper/css/navigation';
import "./slider.css";
import 'swiper/css/effect-fade';
import Slider1 from "../assets/slider1.jpg";
import Slider2 from "../assets/slider2.jpg";
import Slider3 from "../assets/slider3.jpg";
import Slider4 from "../assets/slider4.jpg";
import {BsArrowRight} from 'react-icons/bs';
import {BsArrowLeft} from 'react-icons/bs';

const Slider = () => {
    return (
        <div className='homeSlider'>
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                effect={"fade"}
                navigation={{
                    nextEl: ".button-next-slide",
                    prevEl: ".button-prev-slide"
                }}
                loop={true}
                autoplay={{
                    "delay": 1,
                    "disableOnInteraction": false,
                    "pauseOnMouseEnter": false,
                    "stopOnLastSlide": false,
                    "waitForTransition": true
                }}
                modules={[Navigation, EffectFade]}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
            >
                <SwiperSlide>
                    <div className="sliderImage">
                        <img src={Slider3} alt="" />
                    </div>
                    <div className="slider-content">
                        <motion.h3 className="title" animate={{x:0,scale:1}} transition={{ease: "anticipate",duration: 1.2}} initial={{x:100,scale:0}}>Welcome To <br /> Shaaria Corporation</motion.h3>
                        <motion.p className="para" animate={{x:0,scale:1}} transition={{ease: "anticipate",duration: 1.2}} initial={{x:100,scale:0}}>WE WEAVE YOUR ATTIRE.</motion.p>
                        <motion.div animate={{x:0,scale:1}} transition={{ease: "anticipate",duration: 1.2}} initial={{x:100,scale:0}}>
                            <a href="/About" className="button">Know More</a>
                        </motion.div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="sliderImage">
                        <img src={Slider1} alt="" />
                    </div>
                    {/* <div className="slider-content" >
                        <h3  className="title">Uniform</h3>
                        <p className="para">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quos dolorem repudiandae quis nostrum!</p>
                        <div>
                            <a href="#" className="button">Know More</a>
                        </div>
                    </div> */}
                </SwiperSlide>

                <SwiperSlide>
                    <div className="sliderImage">
                        <img src={Slider4} alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="sliderImage">
                        <img src={Slider2} alt="" />
                    </div>
                </SwiperSlide>


                <div className="button-next-slide">
                    <BsArrowRight />
                </div>
                <div className="button-prev-slide">
                    <BsArrowLeft />
                </div>
            </Swiper>
        </div>
    )
}

export default Slider;