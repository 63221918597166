import React from 'react';
import Hero from "../components/Hero";
import AboutImg from "../assets/suitingBg.jpg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SliderProduct from "../components/SliderProduct";

import img1 from '../assets/productSlider/poly-base1.jpg';
import img2 from '../assets/productSlider/poly-base2.jpg';
import img3 from '../assets/productSlider/poly-base3.jpg';
import img4 from '../assets/productSlider/poly-base4.jpg';
import img5 from '../assets/productSlider/poly-base5.jpg';
import img6 from '../assets/productSlider/poly-base6.jpg';

const Polybase = () => {
  return (
    <>
      <Navbar />

      <Hero
        hCName="aboutHero"
        hImg={AboutImg}
        hTitle="Suitings"
        hPara="Home / Products / Suitings"
      />

      <SliderProduct
        Title="Poly Base"
        Text1="We have a wide range of 100% Poly suiting in Plain, Stripes, Check, Dobby, Printed etc. We are strong in polyester blended fabrics. We produce 1.5 lakh mtrs of fibre dyed and piece dyed fabric every month."
        Text2="Our synthetic range starts from 100% polyester and goes to fine PV fabric range."
        imgsrc1={img1}
        imgsrc2={img2}
        imgsrc3={img3}
        imgsrc4={img4}
        imgsrc5={img5}
        imgsrc6={img6}
        />
      <Footer />
    </>
  )
}

export default Polybase;