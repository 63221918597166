import React from 'react';
import Hero from "../components/Hero";
import AboutImg from "../assets/womens_bg2.jpg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SliderProduct from "../components/SliderProduct";

import img1 from '../assets/productSlider/womens1.jpg';
import img2 from '../assets/productSlider/womens2.jpg';
import img3 from '../assets/productSlider/womens3.jpg';
import img4 from '../assets/productSlider/womens4.jpg';
import img5 from '../assets/productSlider/womens5.jpg';
import img6 from '../assets/productSlider/womens7.jpg';

const Women = () => {

    const list = <ul className='GarmentList'>
        <li><b>Trousers:</b> Regular fit, slim fit,  narrow bottom, flexi waist, tailored fit, wide leg, cropped hem, chinos etc.</li>
        <li><b>Pants:</b> Formal pants, casual pants, semi formal pants, cigarette fit etc.</li>
        <li><b>Pinafore:</b> Classic fit, box pleat, empire waist, flared, long, mini, v neck etc.</li>
        <li><b>Long skirts:</b> Pencil skirt, fish-cut, flared, a-line skirt etc.</li>
        <li><b>Short skirts:</b> Pencil mini, high low formal, peplum etc.</li>
        <li><b>Suits:</b> Coat and pant.</li>
        <li><b>Coats:</b> Full sleves & vest coats.</li>
    </ul>

    return (
        <>
            <Navbar />

            <Hero
                hCName="aboutHero"
                hImg={AboutImg}
                hTitle="Garments"
                hPara="Home / Products / Garments"
            />

            <SliderProduct
                Title="Formal Women's Wear"
                Text1={list}
                imgsrc1={img1}
                imgsrc2={img2}
                imgsrc3={img3}
                imgsrc4={img4}
                imgsrc5={img5}
                imgsrc6={img6}
            />
            <Footer />
        </>
    )
}

export default Women;