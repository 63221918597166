import React from 'react';
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AboutCard from '../components/AboutCard';
import Img from '../assets/mission-inner.jpg';
import Img2 from '../assets/mission.jpg';

const Mission = () => {
    return (
        <>
            <Navbar />

            <Hero
                hCName="aboutHero"
                hImg={Img}
                hTitle="Our Mission"
                hPara="Home / Overview / Mission"
            />
            
            <AboutCard
                    Img={Img2}
                    Title="Our Mission"
                    Text1="We are into business of providing the best fabric and garments we can with the time limitations given to us. we make sure that the required quality is finished under the required standards and also is not hazardious to the human race and environment."
                    cls ="read-more-btn displayNone"
                />

            
            <Footer />
        </>
    )
}

export default Mission;