
import Product1 from '../assets/product1.png';
import Product2 from '../assets/product2.png';
import Product3 from '../assets/product3.png';
import Product4 from '../assets/product4.png';

const ProductData = [
    {
        imgsrc: Product1,
        url : "/cotton",
        title : "Suitings",
        cat : "Organic",
    },
    {
        imgsrc: Product2,
        url : "/Shirtings",
        title : "Shirtings",
        cat : "BCI, Organic, PSCP, GRS, IKEA ",
    },
    {
        imgsrc: Product3,
        url : "/Garments",
        title : "Garments",
        cat : "BCI, Organic, PSCP, GRS, IKEA",
    },
    {
        imgsrc: Product4,
        url : "/Uniforms",
        title : "Uniforms",
        cat : "BCI, Organic, PSCP, GRS, IKEA",
    }
];

export default ProductData;
