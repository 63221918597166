import React from 'react';
import Hero from "../components/Hero";
import AboutImg from "../assets/suitingBg.jpg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SliderProduct from "../components/SliderProduct";


import img1 from '../assets/productSlider/cotton1.jpg';
import img2 from '../assets/productSlider/cotton2.jpg';
import img3 from '../assets/productSlider/cotton3.jpg';
import img4 from '../assets/productSlider/cotton4.jpg';
import img5 from '../assets/productSlider/cotton5.jpg';
import img6 from '../assets/productSlider/cotton6.jpg';

const Cotton = () => {
    return (
        <>
            <Navbar />

            <Hero
                hCName="aboutHero"
                hImg={AboutImg}
                hTitle="Suitings"
                hPara="Home / Products / Suitings"
            />

            <SliderProduct 
            Title ="Cotton"
            Text1="We produce 100% Cotton, Poly cotton 65:35, 80:20, 50:50 in Plain, Twill Satin & dobby weaves. We also do prints and dyed cotton fabric for both suitings and shirtings as per our buyers requirements. "
            Text2="Also, we have digital printing facility inhouse and have the capacity to produce about half a million meters of fabric in a year."
            imgsrc1={img1}
            imgsrc2={img2}
            imgsrc3={img3}
            imgsrc4={img4}
            imgsrc5={img5}
            imgsrc6={img6}
            />
            <Footer />
        </>
    )
}

export default Cotton;