import Navbar from "../components/Navbar";
import Slider from "../components/Slider";
import Product from "../components/Product";
import Banners from "../components/Banners";
import Testimonial from "../components/Testimonial";
import Footer from "../components/Footer";
import AboutCard from "../components/AboutCard";
import AboutImg from '../assets/aboutus.jpg';

function Home() {
    return (
        <>
            <Navbar />
            <Slider />
            <AboutCard
                Img={AboutImg}
                Title="Overview"
                Text1="Shaaria Corporation is a conclusion of 25 years expertise and experience of the management team. We are committed for perfection in our pre and post sales. We at Shaaria always strive to come up with innovative ideas, new product research, new development and trying to deliver the best product to our buyers is our ultimate goal. We have a variety range of Suiting’s, Shirting’s, Uniforms and Garments. We also deal in African wax prints."
                cls="button"
                url="/About"
            />
            {/* <Brand /> */}
            <Product />
            <Banners />
            <Testimonial />
            <Footer />
        </>
    )
}

export default Home;