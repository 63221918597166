import React from 'react';
import "./footer.css";
import { Link } from 'react-router-dom';
import Logo1 from '../assets/logo1.png';

const Footer = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    
    return (
        <>
            <footer className="footer-area mt--100">
                <div className="footer-top pt--50 section-pb">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="footer-info-area">
                                    <div className="footer-logo">
                                        <Link to="#"><img src={Logo1} alt="" /></Link>
                                    </div>
                                    <div className="desc_footer">
                                        <p><i className="fa fa-home"></i> <span> <strong style={{color: '#b46f36'}}>Head Office: Mumbai Address</strong> - Unit-5, Mangalya Ind Prem Co-op Soc. Ltd.,Bldg No. 7, Mittal Ind Estate, Ak Road, Andheri - East, Marol Naka,mumbai Suburan,	Mumbai, Maharashtra - 400059 <br />GSTIN: 27aeufs7452l1z1</span> </p>
                                        <p><i className="fa fa-home"></i> <span> <strong  style={{color: '#b46f36'}}>Marketing Office: Ahmedabad Address</strong> - F-1010-1011,10th Floor, Titanium City Centre Mall, 100 Feet Rd, Prahlad Nagar, Ahmedabad, India - 380015 <br />Mobile No. : +91 88505 83063	</span> </p>
                                        <p><i className="fa fa-phone"></i> <span>  (+91) 885 058 3063</span> </p>
                                        <p><i className="fa fa-envelope"></i> <span> info@shaaria.com</span> </p>
                                        {/* <div className="link-follow-footer">
                                            <ul className="footer-social-share">
                                                <li><Link to="#"><i className="fa fa-instagram"></i></Link></li>
                                                <li><Link to="#"><i className="fa fa-rss"></i></Link></li>
                                                <li><Link to="#"><i className="fa fa-google-plus"></i></Link></li>
                                                <li><Link to="#"><i className="fa-regular fa-facebook"></i></Link></li>
                                                <li><Link to="#"><i className="fas fa-youtube"></i></Link></li>
                                            </ul>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="footer-info-area footer-info-areaLink">
                                    <div className="footer-title">
                                        <h3>Quick Links</h3>
                                    </div>
                                    <div className="desc_footer">
                                        <ul>
                                            <li><Link onClick={scrollToTop} to="/">Home</Link></li>
                                            <li><Link onClick={scrollToTop} to="/About"> Overview</Link></li>
                                            <li><Link onClick={scrollToTop} to="/career">  Career</Link></li>
                                            <li><Link onClick={scrollToTop} to="/Contact">  Contact Us</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="footer-info-area footer-info-areaLink">
                                    <div className="footer-title">
                                        <h3>Products</h3>
                                    </div>
                                    <div className="desc_footer">
                                        <ul>
                                            <li><Link onClick={scrollToTop} to="/cotton">Suitings</Link></li>
                                            <li><Link onClick={scrollToTop} to="/shirtings"> Shirtings</Link></li>
                                            <li><Link onClick={scrollToTop} to="/garments"> Garments</Link></li>
                                            <ul className='sublist'>
                                                <li><Link onClick={scrollToTop} to="/men"> Mens</Link></li>
                                                <li><Link onClick={scrollToTop} to="/women"> Womens</Link></li>
                                            </ul>
                                            <li><Link onClick={scrollToTop} to="/uniforms">  Uniforms</Link></li>
                                            <li><Link onClick={scrollToTop} to="/Sustainable-range">  Sustainable Range</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-4 col-md-12">
                                <div className="footer-info-area">
                                    <div className="footer-title">
                                        <h3>Our Product Categories </h3>
                                    </div>
                                    <div className="desc_footer">
                                        <div className="input-newsletter">
                                            <input name="email" className="input_text" value="" placeholder="Your email address" type="text" />
                                            <button className="btn-newsletter"><i className="fa fa-envelope"></i></button>
                                        </div>
                                        <p>Get E-mail updates about our latest shop and special offers.</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="footer-buttom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-7">
                                <div className="copy-right">
                                    <p>Copyright 2023 <a href="https://shaaria.com/">Shaaria</a>.  All Rights Reserved</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-5">
                                <div className="footer-credit">
                                    <p>Developed By <a href="https://dreamzvision.com/">Dreamz Vision Pvt Ltd.</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <a id="scrollUp" href="#" ><i class="fa fa-angle-double-up"></i></a>
        </>
    )
}

export default Footer;