import "./Hero.css";


function Hero(props) {
    return (
        <>
            <div className={props.hCName}>
                <img src={props.hImg} alt="heroImg" />

                <div className="HeroText">
                    <h1>{props.hTitle}</h1>
                    <p>{props.hPara}</p>
                    <a href={props.hBtnUrl} className={props.hBtn}> {props.hBtnText} </a>
                </div>
            </div>
        </>
    )
}

export default Hero;