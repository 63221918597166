import React from 'react';
import Hero from "../components/Hero";
import AboutImg from "../assets/career.jpg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CareerForm from '../components/CareerForm';




const CareerOverview = () => {
    return (
        <>
            <Navbar />

            <Hero
                hCName="aboutHero"
                hImg={AboutImg}
                hTitle="Career"
                hPara="Home / Career / Overview"
            />

            <div className="container padT80 padB80">
                <div className="row">
                    <div className="col-lg-12 col-md-6">
                        <div className="about-info-wrapper">
                            {/* <h2 className='Title' data-aos="fade-up" data-aos-duration="700">Overview</h2> */}
                            <p className='pt-5' data-aos="fade-up" data-aos-duration="1000">In a world where everything else is equal, human effort makes all the difference. For us, our workforce is our most valuable asset.</p>
                            <p className='' data-aos="fade-up" data-aos-duration="1000">Shaaria Corporation LLP gives an exquisite working environment to its team members with homely atmosphere and safe environment with healthy professional guidance. we at shaaria always look forward to welcoming new talent with fresh aim and intentions to grow, dedication and enthusiasm towards work and life.</p>
                            <p className='' data-aos="fade-up" data-aos-duration="1000">When you become a part of shaaria, you open ample amount of opportunities like innovating, experimenting and even empowering and touching the heights of success. and all of this becomes possible when we all stay true to ourselves.</p>
                            <p className='' data-aos="fade-up" data-aos-duration="1000">Shaaria’s work culture comprises few very important key attributes which are imbibed by every employee: </p>
                            <ul className='careerList'>
                                <li>Learning ability</li>
                                <li>Employee recognition & development</li>
                                <li>Entrepreneurship</li>
                                <li>High-performance culture</li>
                                <li>Objectivity & transparency</li>
                                <li>Balanced decision making approch</li>
                                <li>Long term perspective</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <CareerForm />
            

            <Footer />
        </>
    )
}

export default CareerOverview;