import React from 'react'

const CareerForm = () => {
 

    return (
        <>
            <div className="content-wraper">
                <div className="container  padB50">
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                            <div className="contact-form">
                                <div className="contact-form-info">
                                    <div className="contact-title">
                                        <h3>Please fill the form to apply for job.</h3>
                                    </div>
                                    <form action='https://shaaria.com/form/sendmail.php' method='POST' id="contact-form" enctype="multipart/form-data">
                                        <div className="contact-page-form">
                                            <div className="contact-input">
                                                <div className="contact-inner">
                                                    <input type="text" placeholder="Name *" name="name" />
                                                </div>
                                                <div className="contact-inner">
                                                    <input type="text" placeholder="Phone *" name="phone" />
                                                </div>
                                                <div className="contact-inner">
                                                    <input type="email" placeholder="Email *" name="email"  />
                                                </div>
                                                <div className="contact-inner">
                                                    <input type="text" placeholder="Address *" name="address" />
                                                </div>
                                                <div className="contact-inner">
                                                    <input type="text" placeholder="Date Of Birth * (DD/MM/YYYY)" name="dob"  />
                                                </div>
                                                <div className="contact-inner">
                                                    <input type="file" placeholder="Upload CV *" name="file" />
                                                </div>
                                                <div className="contact-inner">
                                                    <input type="text" placeholder="Designation *" name="desig" />
                                                </div>
                                            </div>
                                            <div className="contact-submit-btn">
                                                <button className="submit-btn" type="submit" name='submit' >Send Email</button>
                                                {/* <p className="form-messege pt-3">{message}</p> */}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CareerForm;