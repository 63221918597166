import React from 'react'

const ContactInfo = () => {
    return (
        <>
            <div className="contact-infor">
                <div className="contact-title">
                    <h3>CONTACT INFO</h3>
                </div>
                <div className="contact-dec">
                    <p>If you have any query. Pls connect with us. We will back to you asap.</p>
                </div>
                <div className="contact-address">
                    <ul>
                        <li><i className="fa fa-fax"> </i> Head Office: Mumbai Address - Unit-5, Mangalya Ind Prem Co-op Soc. Ltd.,Bldg No. 7, Mittal Ind Estate, Ak Road, Andheri - East, Marol Naka,mumbai Suburan,	Mumbai, Maharashtra - 400059 <br />GSTIN: 27aeufs7452l1z1</li>
                        <li><i className="fa fa-fax"> </i> Marketing Office: Ahmedabad Address - F-1010-1011,10th Floor, Titanium City Centre Mall, 100 Feet Rd, Prahlad Nagar, Ahmedabad, India - 380015 <br />Mobile No. : +91 88505 83063</li>
                        <li><i className="fa fa-envelope">&nbsp;</i> info@shaaria.com</li>
                        <li><i className="fa fa-phone">&nbsp;</i> (+91) 885 058 3063</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default ContactInfo;