import './App.css';
import React, { useEffect } from "react";
import { Route, Routes  } from 'react-router-dom';
import Home from './routes/Home';
import About from './routes/About';
import Contact from './routes/Contact';
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import '../node_modules/aos/dist/aos'; 
import '../node_modules/aos/dist/aos.css'; 
import AOS from "aos";


import Cotton from './routes/Cotton';
import Polybase from './routes/Polybase';
import Polyviscose from './routes/Polyviscose';
import Polywool from './routes/Polywool';
import Shirtings from './routes/Shirtings';
import Garments from './routes/Garments';
import Men from './routes/Men';
import Women from './routes/Women';
import Uniforms from './routes/Uniforms';
import SustainableRange from './routes/SustainableRange';
import CareerOverview from './routes/CareerOverview';
import Vision from './routes/Vision';
import Mission from './routes/Mission';
import Values from './routes/Values';


function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className="App">

     <Routes >
      <Route path='/' element={<Home/>} />
      <Route path='/About' element={<About/>} />
      <Route path='/Contact' element={<Contact/>} />
      <Route path='/Cotton' element={<Cotton />} />
      <Route path='/Polybase' element={<Polybase />} />
      <Route path='/Polyviscose' element={<Polyviscose />} />
      <Route path='/Polywool' element={<Polywool />} />
      <Route path='/Shirtings' element={<Shirtings />} />
      <Route path='/Garments' element={<Garments />} />
      <Route path='/men' element={<Men />} />
      <Route path='/women' element={<Women />} />
      <Route path='/Uniforms' element={<Uniforms />} />
      <Route path='/Sustainable-range' element={<SustainableRange />} />
      <Route path='/career' element={<CareerOverview />} />
      <Route path='/Vision' element={<Vision />} />
      <Route path='/Mission' element={<Mission />} />
      <Route path='/Values' element={<Values />} />
     </Routes>
     
      
      
    </div>
  );
}

export default App;
