
import Product1 from '../assets/productSlider/Sustainable-range2.jpg';
import Product2 from '../assets/productSlider/Sustainable-range3.jpg';
import Product3 from '../assets/productSlider/Sustainable-range5.jpg';

const SustainableData = [
    {
        imgsrc: Product1,
        url : "/Sustainable-range",
    },
    {
        imgsrc: Product2,
        url : "/Sustainable-range",
    },
    {
        imgsrc: Product3,
        url : "/Sustainable-range",
    }
];

export default SustainableData;
