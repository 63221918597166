import React from 'react';
import "./brand.css";
import BannersData from './BannersData';
import { Link } from "react-router-dom";

const Brand = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <>
            <div className="container pt-5 pb-5">
                <div className="row">

                    {
                        BannersData.map((val, index) => {
                            return (
                                <div className="col-lg-6 col-md-6 pb-4 bannerC" key={index}>
                                    <div className="card bannerCard">
                                        <img src={val.imgsrc} className="card-img-top" alt="..." />
                                        <div className=" bannerContent">
                                            <h3 className="BannerTitle">{val.title}</h3>
                                            {/* <p className="BannerPara">{val.para}</p> */}
                                            <Link to={val.url} onClick={scrollToTop} className="btn btn-primary BannerBtn">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default Brand;