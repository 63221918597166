import Hero from "../components/Hero";
import AboutImg from "../assets/ovr-banner.jpg";
import Navbar from "../components/Navbar";
import AboutPage from "../components/AboutPage";
import Footer from "../components/Footer";
// import Breadcrumbs from "../components/Breadcrumbs";

function About () {
    return(
        <>
            <Navbar/>
            
            <Hero 
                hCName="aboutHero" 
                hImg={AboutImg} 
                hTitle="Overview"
                hPara="Home / Overview"
            />
            {/* <Breadcrumbs 
            pName = "About Us"
            /> */}
            <AboutPage />
            <Footer />
        </>
    )
}

export default About;