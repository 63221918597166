import React from 'react';
import Hero from "../components/Hero";
import AboutImg from "../assets/suitingBg.jpg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SliderProduct from "../components/SliderProduct";


import img1 from '../assets/productSlider/poly-visocse1.jpg';
import img2 from '../assets/productSlider/poly-visocse2.jpg';
import img3 from '../assets/productSlider/poly-visocse3.jpg';
import img4 from '../assets/productSlider/poly-visocse4.jpg';
import img5 from '../assets/productSlider/poly-visocse5.jpg';
import img6 from '../assets/productSlider/poly-visocse6.jpg';

const Polyviscose = () => {
    return (
        <>
            <Navbar />

            <Hero
                hCName="aboutHero"
                hImg={AboutImg}
                hTitle="Suitings"
                hPara="Home / Products / Suitings"
            />

            <SliderProduct
                Title="Poly Viscose"
                Text1="We deal in a wide range of PV 65:35, 80:20, 90:10 in Plains, Twill, Satin, Dobby, Jacquard etc. We are capable of doing any kind of value addition. We have Weft and Bi-Stretch range in PV blends. We can do Teflon coatings along with spandex incorporation. We can do various types of finishes like Anti-Microbial, Anti-Static etc."
                imgsrc1={img1}
                imgsrc2={img2}
                imgsrc3={img3}
                imgsrc4={img4}
                imgsrc5={img5}
                imgsrc6={img6}
            />
            <Footer />
        </>
    )
}

export default Polyviscose;