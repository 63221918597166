import React from 'react';
import ContactForm from './ContactForm';
import ContactInfo from './ContactInfo';
import './contact.css';

const ContactUs = () => {
  return (
    <>
    <div className="content-wraper">
        <div className="container padT100 padB50">
            <div className="row">
                <div className="col-lg-7 col-sm-12">
                    <ContactForm />
                </div>
                <div className="col-lg-5 col-sm-12">
                    <ContactInfo />
                </div>
            </div>
        </div>
        <div className="container padB50">
            <div className="row">
                <div className="col-lg-12 col-sm-12">
                <iframe title="myFrame" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d98821.52763133479!2d72.40334501052853!3d23.00625216088903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b2afb683a79%3A0x900eae4675c049da!2sShaaria%20Corporation!5e0!3m2!1sen!2sin!4v1692354647633!5m2!1sen!2sin" width="100%" height="450" allowfullscreen=""></iframe>
                </div>
            </div>
        </div>
    </div>
</>
  )
}

export default ContactUs;