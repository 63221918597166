import Img1 from '../assets/men_formal.jpg';
import Img2 from '../assets/women_formal.jpg';

const BannersData = [
    {
        imgsrc: Img1,
        url : "/men",
        title : "MEN FORMAL WEAR",
        para : "It is a long established fact that a reader will be distracted by the readable content of a page.",
    },
    {
        imgsrc: Img2,
        url : "/women",
        title : "WOMEN FORMAL WEAR",
        para : "It is a long established fact that a reader will be distracted by the readable content of a page.",
    }
];

export default BannersData;