import React from 'react';
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AboutCard from '../components/AboutCard';
import Img from '../assets/vison-inner.jpg';
import Img2 from '../assets/vision.jpg';

const Vision = () => {
    return (
        <>
            <Navbar />

            <Hero
                hCName="aboutHero"
                hImg={Img}
                hTitle="Our Vision"
                hPara="Home / Overview / Vision"
            />
            
            <AboutCard
                    Img={Img2}
                    Title="Our Vision"
                    Text1="Our goal is to satisfy every customer we have in our palate with the best we have and to improve more with the mistakes we can learn from. We not only want to widden our scope of fabrics but we are marching forward with a dream of becoming the defination of precision on quality and in time parameters."
                    cls ="read-more-btn displayNone"
                />

            
            <Footer />
        </>
    )
}

export default Vision;