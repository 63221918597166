import React from 'react';
import "./brand.css";
import VisionData from './VisionData';

const Vision = () => {
    return (
        <>
            <div className="container pt-5 pb-5">
                <div className="row">

                    {
                        VisionData.map((val, index) => {
                            return (
                                <div className="col-lg-4 col-md-6 pb-4 bannerC" data-aos="fade-up" data-aos-duration="2000" key={index}>
                                    <div className="card bannerCard visionCard">
                                        <img src={val.imgsrc} className="card-img-top" alt="..." />
                                        <div className="VisionContent">
                                            <div className="iconn">
                                                <i className={val.iClass}></i>
                                            </div>
                                            <div><h3 className="BannerTitle">{val.title}</h3></div>
                                            <div className='mt-4'><a href={val.url} className="ViewLink">View</a></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default Vision;